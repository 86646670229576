import { getReportSchemeTree } from 'containers/ReportScheme/actions'

import { DATATYPE_FORMULA } from 'containers/ReportSelections/constants'
import { transformDatasource } from 'containers/ReportSelections/Transformers/reportSelectionToFlexmonsterConfig'
import { lowerFirst } from 'lodash'

const AMOUNT = 'amount'
const DATASOURCE = 'datasource'
const ACTIVE = 'active'

export const getFirstFreeNameWithoutCopy = (datasources, name, ignoreId) => {
  const baseName = name?.split(' (copy')?.[0]
  const copysCount = datasources.filter(
    (d) => d.name.startsWith(baseName) && d.id !== ignoreId
  ).length
  for (let i = 0; i <= copysCount; i++) {
    let copyName = `${baseName} (copy ${i})`
    if (i === 0) copyName = baseName
    if (i === 1) copyName = `${baseName} (copy)`
    if (!datasources.find((d) => d.name === copyName && d.id !== ignoreId)) {
      return copyName
    }
  }
  return baseName
}

export const mutilateVisualizationToFitForm = ({
  visualizationOverride,
  form,
  reportSelectionToDisplay,
}) => {
  const measures = visualizationOverride?.slice?.measures
  if (!measures) return visualizationOverride

  const activeMeasures = measures.filter(
    (measure) => measure.active || !(ACTIVE in measure)
  )

  const hasNewFormula = form.reportDataSources.some(
    (rds) =>
      rds.dataType === DATATYPE_FORMULA &&
      reportSelectionToDisplay.reportDataSources.every(
        (oldRds) => oldRds.id !== rds.id
      )
  )

  const hasNewDatasource = form.reportDataSources.some((rds) =>
    reportSelectionToDisplay.reportDataSources.every(
      (oldRds) => oldRds.id !== rds.id
    )
  )

  const hasEditedFormulaDataSources = form.reportDataSources.some((rds) =>
    reportSelectionToDisplay.reportDataSources.some(
      (oldRds) =>
        oldRds.id === rds.id &&
        rds.dataType === DATATYPE_FORMULA &&
        oldRds.formula !== rds.formula
    )
  )

  if (
    activeMeasures.length === 1 &&
    activeMeasures[0].uniqueName === AMOUNT &&
    !hasNewFormula
  )
    return visualizationOverride

  const newVisualizationOverride = { ...visualizationOverride }

  handleEditedDataSources(
    newVisualizationOverride,
    form,
    reportSelectionToDisplay
  )
  const hasAmountMeasure = measures.some(
    (measure) => measure.uniqueName === AMOUNT
  )

  const createMeasures =
    (hasNewFormula && hasAmountMeasure) ||
    (hasNewDatasource && !hasAmountMeasure) ||
    (hasEditedFormulaDataSources && !hasAmountMeasure)

  if (createMeasures) {
    handleMeasureCreation(
      newVisualizationOverride,
      form,
      reportSelectionToDisplay,
      hasAmountMeasure
    )
  }

  handleDeletedDataSources(
    newVisualizationOverride,
    reportSelectionToDisplay,
    form
  )

  return newVisualizationOverride
}

const handleMeasureCreation = (
  newVisualizationOverride,
  form,
  reportSelectionToDisplay,
  hasAmountMeasure
) => {
  const dataSourceMeasures = newVisualizationOverride?.slice?.measures?.filter(
    (measure) =>
      form.reportDataSources.some(
        (rds) => lowerFirst(rds.name) === measure.uniqueName
      )
  )

  const measureOrder = newVisualizationOverride.slice.measures.map(
    (measure) => measure.uniqueName
  )

  const newMeasures = form.reportDataSources.map((rpds) => {
    const isNewDataSource = reportSelectionToDisplay.reportDataSources.every(
      (rds) => rds.id !== rpds.id
    )
    const transformedDatasource = transformDatasource({
      reportDataSource: rpds,
      activeState:
        hasAmountMeasure ||
        isNewDataSource ||
        !!dataSourceMeasures.find(
          (measure) =>
            measure.uniqueName === lowerFirst(rpds.name) &&
            (!(ACTIVE in measure) || measure.active)
        ),
    })

    const format =
      dataSourceMeasures.find(
        (measure) => measure.uniqueName === lowerFirst(rpds.name)
      )?.format || ''

    return {
      ...transformedDatasource,
      format: transformedDatasource.format ?? format,
    }
  })

  const isFormulaDataSourceUsed = form.reportDataSources.some(
    (rds) => rds.dataType === DATATYPE_FORMULA
  )

  if (isFormulaDataSourceUsed) {
    newVisualizationOverride.slice.columns = newVisualizationOverride.slice.columns.filter(
      (column) => column.uniqueName !== DATASOURCE
    )
    newVisualizationOverride.slice.measures = newVisualizationOverride.slice.measures.filter(
      (measure) => measure.uniqueName !== AMOUNT
    )
  }

  const flexmonsterMeasuresToKeep = newVisualizationOverride.slice.measures.filter(
    (measure) => {
      if (!measure.formula)
        return newMeasures.every((nm) => nm.uniqueName !== measure.uniqueName)
      return newMeasures.every((nm) => {
        if (nm.uniqueName === measure.uniqueName) {
          return measure.formula !== nm.formula
        }
        if (nm.formula === measure.formula) {
          return measure.uniqueName !== nm.uniqueName
        }
        return true
      })
    }
  )

  const combinedMeasures = [...newMeasures, ...flexmonsterMeasuresToKeep]

  // Order measures in the same order as the original visualization
  const measuresInOrder = measureOrder
    .map((measureName) =>
      combinedMeasures.find((measure) => measure.uniqueName === measureName)
    )
    .filter(Boolean)

  // Add measures that are not in the original visualization to the end of the list
  const missingMeasures = combinedMeasures.filter(
    (measure) =>
      !measuresInOrder.some((m) => m.uniqueName === measure.uniqueName)
  )

  newVisualizationOverride.slice.measures = [
    ...measuresInOrder,
    ...missingMeasures,
  ]
}

const handleDeletedDataSources = (
  newVisualizationOverride,
  reportSelectionToDisplay,
  form
) => {
  const deletedDatasources = reportSelectionToDisplay.reportDataSources.filter(
    (ds) => !form.reportDataSources.some((rds) => rds.id === ds.id)
  )

  newVisualizationOverride.slice.measures = newVisualizationOverride.slice.measures.filter(
    (measure) => {
      if (!measure.formula) {
        return true
      }
      return !deletedDatasources.some(
        (deleted) =>
          measure.uniqueName.toLowerCase() === deleted.name.toLowerCase() ||
          measure.formula
            .toLowerCase()
            .includes(`"${deleted.name}"`.toLowerCase())
      )
    }
  )
}

const handleEditedDataSources = (
  newVisualizationOverride,
  form,
  reportSelectionToDisplay
) => {
  const newDatasources = form.reportDataSources
  const oldDatasources = reportSelectionToDisplay.reportDataSources
  const changedDatasources = oldDatasources
    .filter((oldDs) => {
      const newDs = newDatasources.find(
        (newDs) => newDs.id === oldDs.id && newDs.name !== oldDs.name
      )
      return newDs !== undefined
    })
    .map((oldDs) => ({
      id: oldDs.id,
      formattedOldName: lowerFirst(oldDs.name),
      oldName: oldDs.name,
      formattedNewName: lowerFirst(
        newDatasources.find((newDs) => newDs.id === oldDs.id).name
      ),
      newName: newDatasources.find((newDs) => newDs.id === oldDs.id).name,
    }))

  newVisualizationOverride.slice?.recolorings?.map((recoloring) => {
    if (!recoloring.uniqueName.includes(DATASOURCE)) return recoloring
    changedDatasources.forEach((changedDs) => {
      recoloring.uniqueName = recoloring.uniqueName.replaceAll(
        new RegExp(changedDs.formattedOldName, 'g'),
        changedDs.formattedNewName
      )
    })
    return recoloring
  })

  if (changedDatasources.length > 0) {
    newVisualizationOverride.slice.measures = newVisualizationOverride.slice.measures.map(
      (measure) => {
        changedDatasources.forEach((changedDs) => {
          if (measure.uniqueName === changedDs.formattedOldName) {
            measure.uniqueName = changedDs.formattedNewName
            measure.caption = changedDs.formattedNewName
          } else if (measure.formula) {
            if (measure.formula.includes(`"${changedDs.formattedOldName}"`)) {
              measure.formula = measure.formula.replaceAll(
                `"${changedDs.formattedOldName}"`,
                `"${changedDs.formattedNewName}"`
              )
            } else if (measure.formula.includes(`"${changedDs.oldName}"`)) {
              measure.formula = measure.formula.replaceAll(
                `"${changedDs.oldName}"`,
                `"${changedDs.newName}"`
              )
            }
          } else {
            return measure
          }
        })
        return measure
      }
    )

    if (newVisualizationOverride.conditions) {
      newVisualizationOverride.conditions = newVisualizationOverride.conditions.map(
        (condition) => {
          changedDatasources.forEach((changedDs) => {
            if (condition.measure === changedDs.formattedOldName) {
              condition.measure = changedDs.formattedNewName
            }
          })
          return condition
        }
      )
    }
  }
}

export const fetchMissingReportSchemeTrees = ({
  form,
  reportSchemeTrees,
  reportSchemes,
  dispatch,
}) => {
  if (!form || !reportSchemeTrees || !reportSchemes || !dispatch) return
  const reportSchemeIds = form.reportDataSources
    ?.map((ds) => ds.reportSchemeId)
    ?.filter((id) => !!id)
  reportSchemeIds?.push(form?.reportSchemeId)
  const uniqueIds = [...new Set(reportSchemeIds)]
  const missingAndNotLoading = uniqueIds?.filter(
    (id) =>
      !reportSchemeTrees.getIn([id, 'tree']) &&
      !reportSchemeTrees.getIn([id, 'loading'])
  )
  missingAndNotLoading?.forEach((id) => {
    if (!id) return
    const companyCode = reportSchemes?.findKey((company) =>
      company.find((scheme) => scheme.id === id)
    )
    dispatch(
      getReportSchemeTree({
        companyCode,
        id,
      })
    )
  })
}
