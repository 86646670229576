/*
 *
 * ReportScheme reducer
 *
 */
import { List, Set, Map } from 'immutable'

import { ReportSchemeRowGroupRecord, ReportSchemeRowRecord } from 'records'
import {
  COPY_REPORT_SCHEME_ROW,
  COPY_REPORT_SCHEME_ROW_SUCCESS,
  COPY_REPORT_SCHEME_ROW_ERROR,
  CREATE_REPORT_SCHEME_ROW,
  CREATE_REPORT_SCHEME_ROW_ERROR,
  CREATE_REPORT_SCHEME_ROW_SUCCESS,
  DELETE_REPORT_SCHEME_ROW,
  DELETE_REPORT_SCHEME_ROW_ERROR,
  DELETE_REPORT_SCHEME_ROW_SUCCESS,
  GET_REPORT_SCHEME_TREE,
  GET_REPORT_SCHEME_TREE_ERROR,
  GET_REPORT_SCHEME_TREE_SUCCESS,
  MOVE_REPORT_SCHEME_ROW,
  MOVE_REPORT_SCHEME_ROW_ERROR,
  MOVE_REPORT_SCHEME_ROW_SUCCESS,
  TOGGLE_PERCENTAGE_TARGET,
  TOGGLE_PERCENTAGE_TARGET_ERROR,
  TOGGLE_PERCENTAGE_TARGET_SUCCESS,
  UPDATE_REPORT_SCHEME_ROW,
  UPDATE_REPORT_SCHEME_ROW_ERROR,
  UPDATE_REPORT_SCHEME_ROW_SUCCESS,
} from './constants'

// const initialForScheme = (id) =>
//   fromJS({
//     allGroupsCollapsed: true,
//     error: false,
//     groupIds: new Set(),
//     id: id,
//     loading: false, // loading is set to true when appropriate action is handled.
//     openGroupIds: new Set(),
//     tree: undefined,
//   })

const initialState = Map()

const getGroupIds = (node) => {
  if (node.children) {
    const flattenedChildren = node.children.flatMap(getGroupIds)
    const flattenedChildrenArray = flattenedChildren.toArray
      ? flattenedChildren.toArray()
      : []
    return Set([node.id].concat(flattenedChildrenArray))
  }
  return undefined
}

const mapReportSchemeTree = ({ node, openGroupIds = new Set(), level = 0 }) => {
  if (node.children) {
    return new ReportSchemeRowGroupRecord(node)
      .set(
        'children',
        List(
          node.children.map((childNode) =>
            mapReportSchemeTree({
              node: childNode,
              openGroupIds,
              level: level + 1,
            })
          )
        )
      )
      .set('level', level)
      .set('isCollapsed', !openGroupIds.includes(node.id))
  }

  return new ReportSchemeRowRecord(node).set('level', level)
}

const reportSchemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case COPY_REPORT_SCHEME_ROW:
    case MOVE_REPORT_SCHEME_ROW:
    case CREATE_REPORT_SCHEME_ROW:
    case UPDATE_REPORT_SCHEME_ROW: {
      return state.setIn([action.reportSchemeId, 'loading'], true)
    }
    case COPY_REPORT_SCHEME_ROW_ERROR: //
    case CREATE_REPORT_SCHEME_ROW_ERROR:
    case DELETE_REPORT_SCHEME_ROW_ERROR:
    case GET_REPORT_SCHEME_TREE_ERROR:
    case MOVE_REPORT_SCHEME_ROW_ERROR:
    case TOGGLE_PERCENTAGE_TARGET_ERROR:
    case UPDATE_REPORT_SCHEME_ROW_ERROR:
      return state
        .setIn([action.error?.reportSchemeId, 'loading'], false)
        .setIn([action.error?.reportSchemeId, 'error'], action.error)

    case COPY_REPORT_SCHEME_ROW_SUCCESS:
    case MOVE_REPORT_SCHEME_ROW_SUCCESS:
    case CREATE_REPORT_SCHEME_ROW_SUCCESS:
    case DELETE_REPORT_SCHEME_ROW_SUCCESS:
    case GET_REPORT_SCHEME_TREE_SUCCESS:
    case UPDATE_REPORT_SCHEME_ROW_SUCCESS: {
      const tree = mapReportSchemeTree({
        node: action.tree.schemeGroup,
        openGroupIds: state.get('openGroupIds'),
      })
      return state
        .setIn([action.reportSchemeId, 'loading'], false)
        .setIn([action.reportSchemeId, 'tree'], tree)
        .setIn(['latest', 'tree'], tree) // for legacy report selection component. TODO: remove when legacy report selection is removed
        .setIn([action.reportSchemeId, 'groupIds'], getGroupIds(tree))
    }

    case DELETE_REPORT_SCHEME_ROW:
    case TOGGLE_PERCENTAGE_TARGET:
    case GET_REPORT_SCHEME_TREE:
      return state.setIn([action.reportSchemeId, 'loading'], true)

    case TOGGLE_PERCENTAGE_TARGET_SUCCESS: {
      return state.setIn([action.reportSchemeId, 'loading'], false)
    }

    default:
      return state
  }
}

export default reportSchemeReducer
